import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getOfferDetail } from "store/actions/VendorAction";
import { getAwsUrl } from "utils/AwsHelper";
import { dateDifferenceDays } from "utils/DateHelper";
import ImageComponent from "../ImageComponent";
import { Preloader } from "../Preloader";
export default function OfferDetailModal(props) {
  const { showModal, handleOnModalClose, offerId } = props;
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (showModal) {
      offerDetail(offerId);
    }
  }, [showModal]);
  const offerDetail = (offerId) => {
    setLoading(true);
    var params = {
      id: offerId,
    };
    getOfferDetail(params, (response) => {
      setLoading(false);
      if (response) {
        setDetails(response);
      }
    });
  };
  return (
    <Modal
      show={showModal}
      onHide={handleOnModalClose}
      id="offerdetailModal"
      size="md"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <h6 className="modal-title text-uppercase" id="transhistoryModalLabel">
          Offer Detail
        </h6>
      </Modal.Header>
      <Modal.Body>
        <div
          className="custom-scrollbar overflow-scroll-section overflow-section-height"
          style={{ maxHeight: "30em" }}
        >
          <div className="container">
            {loading && <Preloader height={"50px"} extraClass={"p-7"} />}
            {!loading && Object.keys(details).length > 0 && (
              <div className="border-0 card">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="d-flex flex-column justify-content-center">
                      <div className="main_image">
                        <img
                          src={getAwsUrl(details.banner)}
                          alt={details.title}
                          loading="lazy"
                          className="img-fluid rounded-3 d-none"
                        />
                        <ImageComponent
                          src={getAwsUrl(details.banner)}
                          extraClass={"img-fluid rounded-3"}
                          loadingClass={"img-fluid rounded-3"}
                          showLoading={false}
                          alt={details.title}
                        />
                      </div>
                    </div>
                    <div className="pro-description mt-3">
                      <p className="text-gray-700 text-sm mt-3 mb-2">
                        <i className="far fa-clock me-2"></i>Offer expires in{" "}
                        {dateDifferenceDays(details.end_date)} days
                      </p>
                      <div className="align-items-baseline d-flex justify-content-between">
                        <h5 className="text-primary">{details.title}</h5>
                      </div>
                      <div className="mt-2 pr-3 content">
                        <p>{details.description}</p>
                      </div>
                    </div>
                    <div>
                      {details.terms_data.length > 0 && (
                        <>
                          <h6 className="text-gray-800">Terms & conditions</h6>
                          <ul>
                            {details.terms_data.map((list, index) => (
                              <li key={index}>{list}</li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
