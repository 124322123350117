import { connect } from "react-redux";
import React, {useState} from 'react'

import {
    userLocationSelector, userInfoSelector
  } from "components/_selectors/authSelectors";

function CardDetails({ userInfo }) {
    const [cardVisible, setCardVisible] = useState(false);
  return (
    <div className="dropdownCardMenuBlock border dropdown-menu dropdown-menu-end min-width-320px px-2 w-50" aria-labelledby="dropdownMenuButton" style={{ "display": "none", "transform": "translateY(30px)" }}>
        <div className="border-0 card">
            <div className="card-header bg-primary-100 py-2 border-0">
                <div className="d-flex align-items-center justify-content-between">
                <div>
                    <p className="subtitle text-sm text-primary">card details</p>
                </div>
                </div>
            </div>
            <div className="card-body">
                <div className="d-flex justify-content-between mb-2">
                <div>
                    <img src="/images/chip.png" alt="card-chip" />
                </div>
                <div>
                    <i className="fa fa-wifi fa-rotate-90 text-gray-700"></i>
                </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                <div>
                    {(cardVisible && 
                        <span>{ userInfo?.profileData.mask_card }</span>
                    )}
                    {(!cardVisible && 
                        <span>**** **** **** { (userInfo?.profileData) ? userInfo.profileData.mask_card.substring(userInfo.profileData.mask_card.length - 4):'' }</span>
                    )}
                </div>
                <div>
                    <i className={`fa text-gray-700 ${(cardVisible)? 'fa-eye':'fa-eye-slash'}`} onClick={() => setCardVisible(!cardVisible)}></i>
                </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                <div className="text-uppercase">
                    { userInfo?.profileData?.first_name+" "+userInfo?.profileData?.last_name }
                </div>
                <div>
                    <i className="fa fa-wallet text-gray-700"></i>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
const  mapStateToProps = (state) => {
    return {
      currentLocation: userLocationSelector(state),
      userInfo: userInfoSelector(state)
    }
  };
export default connect(mapStateToProps,{ })(CardDetails);
