import VerifyOtp from "pages/Login/VerifyOtp";
// import TestRoute from "pages/TestRoute";
import React, { lazy, Suspense } from "react";
import ProtectedRoute from "routes/ProtectedRoute";
import SigninRoute from "routes/SigninRoute";
const HomePage = lazy(() => import("pages/Home"));
// signin components
const LoginPage = lazy(() => import("pages/Login"));
const VerifyOtpPage = lazy(() => import("pages/Login/VerifyOtp"));
const SigninProfilePage = lazy(() => import("pages/Login/SigninProfile"));
const SigninUploadProfilePage = lazy(() => import("pages/Login/SigninUploadProfile"));

const ProfilePage = lazy(() => import("pages/Profile"));
const MyFollowingPage = lazy(() => import("pages/Profile/MyFollowing"));
const ManageDevicePage = lazy(() => import("pages/Profile/ManageDevice"));
const EarnPointPage = lazy(() => import("pages/Profile/EarnPoints"));
const ReferEarnPage = lazy(() => import("pages/Profile/ReferEarn"));
const RewardPage = lazy(() => import("pages/Profile/Reward"));
const NotificationSetingPage = lazy(() => import("pages/Profile/NotificationSetting"));
const VendorPage = lazy(() => import("pages/Vendors"));
const VendorDetailPage = lazy(() => import("pages/Vendors/Detail"));
const OutletPage = lazy(() => import("pages/Outlets"));
const OutletDetailPage = lazy(() => import("pages/Outlets/Detail"));
const OfferPage = lazy(() => import("pages/Offers"));
const ProductPage = lazy(() => import("pages/Products"));
const AboutUsPage = lazy(() => import("pages/AboutUs"));
export const mainRoutes =  {
    "home":{ "path": "/", "element": <HomePage /> },
    "vendorList":{ "path": "/vendors", "element": <VendorPage /> },
    // offer list
    "offerList":{ "path": "/offers", "element": <OfferPage /> },
    "vendorOfferList":{ "path": "/vendor-offers/:id", "element": <OfferPage /> },
    "outletOfferList":{ "path": "/outlet-offers/:id", "element": <OfferPage /> },
    // outlet list
    "outletList":{ "path": "/outlets", "element": <OutletPage /> },
    "vendorOutletList":{ "path": "/vendor-outlets/:vendorId", "element": <OutletPage /> },
    // vendor/outlet details
    "vendorDetail":{ "path": "/vendor-detail/:id", "element": <VendorDetailPage /> },
    "outletDetail":{ "path": "/outlet-detail/:id", "element": <OutletDetailPage /> },
    // product list
    "vendorProductList":{ "path": "/vendor-products/:id", "element": <ProductPage /> },
    "outletProductList":{ "path": "/outlet-products/:id", "element": <ProductPage /> },
    "aboutUs":{ "path": "/about-us", "element": <AboutUsPage /> },
    // "test":{ "path": "/test-route", "element": <TestRoute /> }
}
export const profileRoutes =  {
    // profile
    "profile":{ "path": "/profile", "element": <ProtectedRoute><ProfilePage /></ProtectedRoute> },
    "myFollowing":{ "path": "/profile/my-following", "element": <ProtectedRoute><MyFollowingPage /></ProtectedRoute> },
    "manageDevice":{ "path": "/profile/manage-device", "element": <ProtectedRoute><ManageDevicePage /></ProtectedRoute> },
    "notificationSetting":{ "path": "/profile/notification-setting", "element": <ProtectedRoute><NotificationSetingPage /></ProtectedRoute> },
    "earnPoint":{ "path": "/profile/earn-point", "element": <ProtectedRoute><EarnPointPage /></ProtectedRoute> },
    "referEarn":{ "path": "/profile/refer-earn", "element": <ProtectedRoute><ReferEarnPage /></ProtectedRoute> },
    "reward":{ "path": "/profile/rewards", "element": <ProtectedRoute><RewardPage /></ProtectedRoute> },
}
export const loginRoutes =  {
    "signin":{ "path": "/signin", "element": <SigninRoute><LoginPage /></SigninRoute> },
    "signinOTP":{ "path": "/signin/verify-account", "element": <ProtectedRoute><VerifyOtpPage /></ProtectedRoute> },
    "signinProfile":{ "path": "/signin/profile", "element": <ProtectedRoute><SigninProfilePage /></ProtectedRoute> },
    "signinUploadProfile":{ "path": "signin/profile-upload", "element": <ProtectedRoute><SigninUploadProfilePage /></ProtectedRoute> },
}