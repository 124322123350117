import { combineReducers } from "redux";
import DashboardReducer from "./DashboardReducer";
import AuthReducer from "./AuthReducer";

const reducers = combineReducers({
  dashboard: DashboardReducer,
  auth: AuthReducer
  //other reducers come here...
});

export default reducers;