import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';
import {
  userInfoSelector
} from "components/_selectors/authSelectors";
import { getUserAuthToken } from "utils/StorageHelper";
import { mainRoutes as routes } from "config/routing";
const SigninRoute = ({ userInfo, children }) => {
  const user = getUserAuthToken();
  if (user) {
    // logged in so redirect to home page with the return url
    return <Navigate to={routes.home.path} replace />
  }
  else{
    return children;
  }
};
const  mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state)
  }
};
export default connect(mapStateToProps,{ })(SigninRoute);