import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Preloader, PreloaderLoadMore } from '../Preloader';

export default function LoadMoreScroll({ children, ...props}) {
    const { totalRecords, getPaginatedData, mainElementExtraClass, preloaderExtraClass, fetchedData, loading, page, emptyMsg, totalFetchedData, ...rest} = props;
  return (
    <InfiniteScroll
        dataLength={totalFetchedData}
        next={getPaginatedData}
        hasMore={!(totalFetchedData == totalRecords)}
        className={mainElementExtraClass}
        loader={<PreloaderLoadMore key={0} height={"50px"} extraClass={"px-7 pt-3 mb-4 "+preloaderExtraClass}/>}
        scrollThreshold={0.6}
        { ...rest }
        >
        {!loading && fetchedData && totalRecords == 0 && (
        <h6 className="p-6 text-center">{ emptyMsg }</h6>
        )}
        { (page == 1) && loading &&
        <Preloader height={"50px"} extraClass={"p-7 intialloader"}/>
        }
        {/* <!-- list --> */}
        { children }
    </InfiniteScroll>
  )
}
