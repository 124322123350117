import moment from 'moment';
import store from 'store';
import { settingsSelector } from 'components/_selectors/authSelectors';

export const getCurrentDateTime = () => {
  return moment();
}
export const getFormattedDate = (date, dateFormat='DD/MM/YYYY') => {
  var dateFormat = 'DD/MM/YYYY';
  if(settingsSelector(store.getState()).date_format_customer_web){
    dateFormat = settingsSelector(store.getState()).date_format_customer_web;
  }
  dateFormat = dateFormat.split(" ")[0];
  return moment(date).format(dateFormat);
}
export const getFormattedTime = (time, dateFormat='h:mm A') => {
   var date = moment().format('YYYY-MM-DD')+" "+time;
    return moment(date).format(dateFormat);
  }
export const getMaxAllowedBirthDate = (dateFormat='YYYY-MM-DD') => {
  return moment(). subtract(10, 'years').format(dateFormat);
}
export const dateDifference = (datetime) => {
  var datetimeFormat = 'DD/MM/YYYY h:mm A';
  if(settingsSelector(store.getState()).date_format_customer_web){
    datetimeFormat = settingsSelector(store.getState()).date_format_customer_web;
  }
  var date = moment(datetime).format('YYYY-MM-DD');
  var time = moment(datetime).format('h:mm A');
  var currentDate = moment().format('YYYY-MM-DD');
  
  var days = moment(currentDate).diff(moment(date), 'days');
  var hours = moment(currentDate).diff(moment(date), 'hours');
  if(hours < 24)
  {
    return "Today, "+time;
  }
  else if(days >=1 && days <= 10 ){
    if(days == 1){
      return "Yesterday, "+time;
    }
    else{
      return days+" days ago "+time;
    }
  }
  else{
    return moment(datetime).format(datetimeFormat);
  }
}
export const dateDifferenceDays = (date1, date2=moment()) => {
  // date1 should be larger than date2
  date1 = moment(date1).format('YYYY-MM-DD');
  date2 = moment(date2).format('YYYY-MM-DD');
  var days = moment(date1).diff(moment(date2), 'days');
  return days;
}
export const dateDifferenceMinutes = (date1, date2=moment()) => {
  // date1 should be larger than date2
  date1 = moment(date1);
  date2 = moment(date2);
  var duration = moment.duration(date1.diff(date2));
  var minutes = duration.asMinutes();
  return Math.round(minutes);
}
// export const dateDifferenceNotification = (datetime, datetimeFormat='DD/MM/YYYY h:mm A') => {
//   // console.log(datetime);
//   var date = moment(datetime).format('YYYY-MM-DD');
//   var currentDate = moment().format('YYYY-MM-DD');
  
//   var days = moment(currentDate).diff(moment(date), 'days');
//   // console.log(days);
//   var minutes = moment(currentDate).diff(moment(date), 'minutes');
//   var hours = moment(currentDate).diff(moment(date), 'hours');
//   var months = moment(currentDate).diff(moment(date), 'months');
//   var years = moment(currentDate).diff(moment(date), 'years');
//   if(minutes <= 1 || minutes < 60)
//   {
//     // minutes
//     if(minutes <= 1){
//       return "A minute ago";
//     }
//     else{
//         return minutes+" minutes ago";
//     }
//   }
//   else if(hours >= 1 && hours <= 24)
//   {
//     // hours
//     if(hours <= 1){
//       return "An Hour ago";
//     }
//     else{
//       return hours+" Hours ago";
//     }
//   }
//   else if(days >=1 && days <= 365 ){
//     return days+" days ago";
//   }
//   else if(months <= 12 ){
//     return months+" months ago";
//   }
//   else{
//     return years+" years ago";
//   }
// }
export const dateDifferenceCalculation = (datetime, datetimeFormat='DD/MM/YYYY h:mm A') => {
  // console.log(datetime);
  var date = moment(datetime);//.format('YYYY-MM-DD h:mm A');
  var currentDate = moment();//.format('YYYY-MM-DD h:mm A');
  
  var days = moment(currentDate).diff(moment(date), 'days');
  // console.log(days);
  var minutes = moment(currentDate).diff(moment(date), 'minutes');
  var hours = moment(currentDate).diff(moment(date), 'hours');
  var months = moment(currentDate).diff(moment(date), 'months');
  var years = moment(currentDate).diff(moment(date), 'years');
  // console.log(days, minutes, hours, months, years);
  if(minutes <= 1 || minutes < 60)
  {
    // minutes
    if(minutes <= 1){
      return "A minute ago";
    }
    else{
        return minutes+" minutes ago";
    }
  }
  else if(hours >= 1 && hours <= 24)
  {
    // hours
    if(hours <= 1){
      return "An Hour ago";
    }
    else{
      return hours+" Hours ago";
    }
  }
  else if(days >=1 && days <= 365 ){
    return days+" days ago";
  }
  else if(months <= 12 ){
    return months+" months ago";
  }
  else{
    return years+" years ago";
  }
}
export const dateDifferenceCalculationReview = (datetime, datetimeFormat='DD/MM/YYYY h:mm A') => {
  // console.log(datetime);
  var date = moment(datetime);//.format('YYYY-MM-DD h:mm A');
  var currentDate = moment();//.format('YYYY-MM-DD h:mm A');
  
  var days = moment(currentDate).diff(moment(date), 'days');
  // console.log(days);
  var minutes = moment(currentDate).diff(moment(date), 'minutes');
  var hours = moment(currentDate).diff(moment(date), 'hours');
  var months = moment(currentDate).diff(moment(date), 'months');
  var years = moment(currentDate).diff(moment(date), 'years');
  // console.log(datetime);
  // console.log(date);
  // console.log(days, minutes, hours, months, years);
  if(minutes <= 1 || minutes < 60)
  {
    // minutes
    if(minutes <= 1){
      return "A minute ago";
    }
    else{
        return minutes+" minutes ago";
    }
  }
  else if(hours >= 1 && hours <= 24)
  {
    // hours
    if(hours <= 1){
      return "An Hour ago";
    }
    else{
      return hours+" Hours ago";
    }
  }
  else if(days <= 30 ){
    // days
    return days+" days ago";
  }
  else if(months <= 12 ){
    // months
    return months+" months ago";
  }
  else{
    // years
    return years+" years ago";
  }
}
export const dateDifferenceCalculationOld = (datetime, datetimeFormat='DD/MM/YYYY h:mm A') => {
  // console.log(datetime);
  var date = moment(datetime).format('YYYY-MM-DD h:mm A');
  var currentDate = moment().format('YYYY-MM-DD h:mm A');
  
  var days = moment(currentDate).diff(moment(date), 'days');
  // console.log(days);
  var minutes = moment(currentDate).diff(moment(date), 'minutes');
  var hours = moment(currentDate).diff(moment(date), 'hours');
  var months = moment(currentDate).diff(moment(date), 'months');
  var years = moment(currentDate).diff(moment(date), 'years');
  console.log(datetime);
  console.log(days, minutes, hours, months, years);
  if(minutes <= 1 || minutes < 60)
  {
    // minutes
    if(minutes <= 1){
      return "A minute ago";
    }
    else{
        return minutes+" minutes ago";
    }
  }
  else if(hours >= 1 && hours <= 24)
  {
    // hours
    if(hours <= 1){
      return "An Hour ago";
    }
    else{
      return hours+" Hours ago";
    }
  }
  else if(days <= 30 ){
    // days
    return days+" days ago";
  }
  else if(months <= 12 ){
    // months
    return months+" months ago";
  }
  else{
    // years
    return years+" years ago";
  }
}

export const getCurrentShortDayName = () => {
    var dayName = moment().format('ddd');
    if(dayName == "Thu"){
        dayName = "Thurs";
    }
    return dayName.toLowerCase();
}