import { DEFAULT_LOCATION }  from "config/config";
const initialstate = {
  currentLocation: DEFAULT_LOCATION,
  isDefaultLocation: true,
  userInfo: null,
  profileData: null,
  settings:{},
  firebaseToken:""
};

const AuthReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "UPDATE_USER_LOCATION":
      var updatedState = {
        ...state, isDefaultLocation: false
      };
      return {
        ...updatedState,
        currentLocation: action.payload
      };
    case "UPDATE_USER_DETAILS":
      return {
        ...state,
        userInfo: action.payload
      };
    case "UPDATE_PROFILE_DATA":
    return {
        ...state,
        profileData: action.payload
    };
    case "UPDATE_SETTINGS":
    return {
        ...state,
        settings: action.payload
    };
    case "UPDATE_FIREBASE_TOKEN":
    return {
        ...state,
        firebaseToken: action.payload
    };
    default:
      return state;
  }
};

export default AuthReducer;
