import React from "react";
import { useEffect, useState } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import ProfileNavBar from "components/Profile/ProfileNavBar";
import config from "config/config";
import { useTranslation } from "react-i18next";
import {mainRoutes as routes, loginRoutes} from 'config/routing';
import BrandLogo from "components/_common/Header/BrandLogo";

export default function SigninLayout({children}) {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  return (
    <div className="container-fluid px-3">
      <div className="row min-vh-100">
        <div className="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center">
          <div className="w-100 py-5 px-md-5 px-xxl-6 position-relative">
            <div className="align-items-center d-flex mb-5">
              {/* <img
                className="img-fluid mb-3 signin-logo"
                src={config.APP_LOGO}
                alt={t("app_name")}
                style={{ maxWidth: "6rem" }}
              />
              <h2 className="mb-0">{t("app_name")}</h2> */}
              <BrandLogo layout="signin" logoClass={"img-fluid signin-logo"}/>
            </div>
            {children}
          </div>
        </div>
        <div className="col-md-4 col-lg-6 col-xl-7 d-none d-md-block">
          {/* <div
            className="bg-cover h-100 me-n3"
            style={{
              backgroundImage:
                "url(/theme/img/photo/photo-1497436072909-60f360e1d4b1.jpg)",
            }}
          ></div> */}
          <div
            className="bg-cover h-100 me-n3"
            style={{
              backgroundImage:
                "url(/images/login_section/signin_image.jpg)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
