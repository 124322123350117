import React from 'react'
import {useEffect, useState} from "react";
import { Routes, Route, Outlet, Link } from 'react-router-dom';
import ProfileNavBar from 'components/Profile/ProfileNavBar';

export default function ProfileLayout(props) {
  const [loading, setLoading] = useState(false);
    return (
        <section className="py-5">
            <div className="container">
                <div className="row position-relative">
                    <ProfileNavBar />
                    <div className="col-lg-9 ps-lg-5">
                        <Outlet />
                    </div>
                </div>
            </div>
        </section>
    )
}
