import React from "react";
import Navbar from "components/_common/Navbar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { mainRoutes as routes } from "config/routing";
import OfferDetailModal from "../Modals/OfferDetailModal";
import ProductDetailModal from "../Modals/ProductDetailModal";
import config from "config/config";
import BrandLogo from "./BrandLogo";

export default function Header() {
  const { t } = useTranslation();
  return (
    <header className="header">
      {/* <!-- Navbar--> */}
      <nav className="p-lg-0 navbar navbar-expand-lg fixed-top shadow navbar-light bg-white">
        <div className="container-fluid">
          <div className="d-flex align-items-center brand-container">
            <Link
              className="navbar-brand py-1 logo align-items-center d-flex"
              to={routes.home.path}
            >
              <BrandLogo />
            </Link>
          </div>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa fa-bars"></i>
          </button>
          {/*<!-- Navbar Collapse --> */}
          <Navbar />
        </div>
      </nav>
      {/*<!-- /Navbar --> */}
      {/* Modals */}
    </header>
  );
}
