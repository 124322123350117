import { connect, useDispatch } from "react-redux";
import React,{ useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from "react-router-dom";
import {mainRoutes as routes, loginRoutes} from 'config/routing';
import {
  userLocationSelector
} from "components/_selectors/authSelectors";
import { getUserInfo } from "utils/StorageHelper";
// import { getCurrentLocation } from "store/actions/AuthAction";
// import { refreshToken } from "store/actions/AuthAction";
// import { errorMessage } from "utils/ToastNotificationHelper";
// import { dateDifferenceDays } from "utils/DateHelper";
import { getSettings } from "store/actions/AuthAction";
import { getFirebaseToken } from "services/firebase.service";
import { manageSettings } from "store/actions/AuthAction";

function HeaderInfo(props) {
  const { title, description, keywords } = props;
  const dispatch = useDispatch();
  const navigate  = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    // setting details
    manageSettings();
    // store user info
    checkLoggedinUser();
  },[])
  function checkLoggedinUser() {
    var userInfo = getUserInfo();
    if(userInfo){
      if(!userInfo.verified){
        if(pathname !== loginRoutes.signinOTP.path){
          // navigate to OTP verify screen
          navigate(loginRoutes.signinOTP.path);
        }
      }
      else if(Object.keys(userInfo.profileData).length > 0 && !userInfo.profileData?.is_profile_completed){
        navigate(loginRoutes.signinProfile.path);
      }
      // else
      // {
      //   navigate(routes.home.path);
      // }
      // get firebase token  with logged in flag true
      getFirebaseToken(userInfo?.fcmToken);
      // set user data in state
      updateUserState(userInfo);
      // if(!userInfo.lastLoggedIn || (userInfo.lastLoggedIn && dateDifferenceDays(userInfo.lastLoggedIn) > 0)){
      //   // call an action
      //   refreshToken({});
      // }
    }
    else{
      // get firebase token with logged in flag false
      getFirebaseToken();
    }
  }
  function updateUserState(userInfo) {
    dispatch({ 
      type: 'UPDATE_USER_DETAILS',
      payload: userInfo 
    })
    if(userInfo?.profileData){
      dispatch({ 
        type: 'UPDATE_PROFILE_DATA',
        payload: userInfo.profileData 
      })
    }
  }
  // function getCurrentLocation(){
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(successLocationFunction, errorLocationFunction);
  //   } else {
  //       alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
  //   }
  // }
  // function successLocationFunction(position) {
  //     var lat = position.coords.latitude;
  //     var long = position.coords.longitude;
  //     dispatch({ 
  //       type: 'UPDATE_USER_LOCATION',
  //       payload: {
  //         lat: lat,
  //         lng: long
  //       } 
  //     })
  //     console.log('Your latitude is :'+lat+' and longitude is '+long);
  // }
  // function errorLocationFunction(error) {
  //     console.log("error for location......");
  //     console.log(error);
  // }
  
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            {description && 
              <meta name="description" content={description} />
            }
            {keywords && 
              <meta name="keywords" content={keywords} />
            }
        </Helmet>
    </>
  )
}
const  mapStateToProps = (state) => {
  return {
    currentLocation: userLocationSelector(state)
  }
};
export default connect(mapStateToProps,{ })(HeaderInfo);
