import axios from "axios";
import { API_URL } from "../config/config";
import {loginRoutes, mainRoutes} from 'config/routing';
import { errorMessage } from "utils/ToastNotificationHelper";
import { removeLocalStorage, getUserAuthToken } from "utils/StorageHelper";
import { refreshToken } from "store/actions/AuthAction";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json"
  }
});

// const token = getUserAuthToken();
// if(token){
//   // config.headers.Authorization =  "Bearer ".token;
//   instance.defaults.headers.common['Authorization'] = "Bearer "+token;
// }
// Add a request interceptor
// instance.interceptors.request.use(function (config) {
//   console.log("api requester........")
// //   const token = getUserAuthToken();
// //   alert(token)
// //   if(token){
// //     // config.headers.Authorization =  "Bearer ".token;
// //     instance.defaults.headers.common['Authorization'] = token;
// //   }
// //   return config;
// });
/**
 * Catch the AunAuthorized Request
 */
instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    if(error.response.data.message == "token_expire"){
      // call an action
      refreshToken(function(){
        redirectHome(false); 
      });
    }
    else{
      // errorMessage('Login Session is expired');
      setTimeout(() => {
        removeLocalStorage("userInfo");
        redirectHome(true);
      }, 1000);
    }
    return false;
  }
});
function redirectHome(isLoggedout=false) {
  // if(window.location.href.includes("profile")){
  if(isLoggedout){
    window.location = loginRoutes.signin.path;  
  }
  else{
    window.location = mainRoutes.home.path;
  } 
}
export default instance;