import React from "react";
import { Link } from "react-router-dom";
import { mainRoutes as routes } from "config/routing";
import { useTranslation } from "react-i18next";
import HeaderInfo from "../Header/HeaderInfo";
export default function NotFound() {
  const { t } = useTranslation();
  return (
    <>
      <HeaderInfo title={ t("app_name") }/>
      <div className="mh-full-screen d-flex align-items-center dark-overlay">
        <img
          className="bg-image"
          src="/theme/img/photo/aron-visuals-3jBU9TbKW7o-unsplash.jpg"
          alt="Not found"
        />
        <div className="container text-white text-lg overlay-content py-6 py-lg-0">
          <h1 className="display-3 fw-bold mb-5">{t("404_page_msg")}</h1>
          <p className="fw-light mb-5 d-none">
            Elit adipisicing aliquip irure non consequat quis ex fugiat dolor in
            irure pariatur eu aute. Ea tempor nisi sit in Lorem exercitation
            mollit ut veniam in do eu excepteur.{" "}
          </p>
          <p className="mb-6">
            <Link to={routes.home.path} className="btn btn-outline-light">
              <i className="fa fa-home me-2"></i>
              {t("start from the homepage")}
            </Link>
          </p>
          <p className="h4 text-shadow">{t("error 404")}</p>
        </div>
      </div>
    </>
  );
}
