import { firebaseTokenSelector } from "components/_selectors/authSelectors";
import config from "config/config";
import { firebaseConfig } from "config/firebase.config";
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import store from "store";
import { refreshFirebaseToken } from "store/actions/AuthAction";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export const getFirebaseToken = (existingToken="") => {
  return getToken(messaging, {vapidKey: config.FIREBASE_VAPID_KEY}).then((currentToken) => {
    if (currentToken) {
      // console.log("token found");
      // console.log(currentToken);
      // console.log('current token for client: ', currentToken);
      if(existingToken != currentToken){
        store.dispatch({ 
          type: 'UPDATE_FIREBASE_TOKEN',
          payload: currentToken 
        })
        if(existingToken){
          console.log("update firebase token");
          // call api to update firebase token
          refreshFirebaseToken({
            "fcm_token": currentToken
          });
        }
      }
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}