import React, { useState } from "react";
import config from "config/config";
import { mainRoutes as routes } from "config/routing";
import { Link } from "react-router-dom";
import { settingsSelector } from "components/_selectors/authSelectors";
import { connect } from "react-redux";
import ContentPageModal from "./ContentPageModal";
function Footer({settings}) {
  const [contentPagemodalDetails, setContentPagemodalDetails] = useState(null);
  const [showContentPageModal, setShowContentPageModal] = useState(false);
  const openContentPageModal = (page) => {
    setContentPagemodalDetails(page);
    setShowContentPageModal(true);
  };
  const handleOnCloseContentPageModal = () => {
    setContentPagemodalDetails(null);
    setShowContentPageModal(false);
  };
  return (
    <footer className="z-index-10 d-print-none">
      { /* footer tab removed class position-relative  */ }
      <div className="py-5 bg-gray-200 text-muted"> 
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-3 mb-5 mb-lg-0">
              <h6 className="text-uppercase text-dark mb-3">Company</h6>
              <ul className="list-unstyled">
                <li>
                  <Link
                    to={routes.aboutUs.path}
                    className={`text-muted`}
                  >
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-3 mb-5 mb-lg-0">
              <div className="fw-bold text-uppercase text-dark mb-3">Contact</div>
              <p>Help & Support</p>
              <ul className="list-inline">
                
                <li className="list-inline-item"><a className="text-muted text-primary-hover" href={config.FACEBOOK_URL} target="_blank" title="Facebook"><i className="fab fa-facebook"></i></a></li>
                <li className="list-inline-item"><a className="text-muted text-primary-hover" href={config.INSTAGRAM_URL} target="_blank" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                <li className="list-inline-item"><a className="text-muted text-primary-hover" href={config.LINKEDIN_URL} target="_blank" title="Linkedin"><i className="fab fa-linkedin"></i></a></li>
                <li className="list-inline-item"><a className="text-muted text-primary-hover" href={config.TWITTER_URL} target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-3 mb-5 mb-lg-0">
              <h6 className="text-uppercase text-dark mb-3">LEGAL</h6>
              {(settings && settings.content_pages) && 
                <ul className="list-unstyled">
                  {/* <li><a className="text-muted" href="#" onClick={(event) => event.preventDefault()}>Terms & Condition</a></li>
                  <li><a className="text-muted" href="#" onClick={(event) => event.preventDefault()}>Privacy Policy</a></li>
                  <li><a className="text-muted" href="#" onClick={(event) => event.preventDefault()}>Offer Terms</a></li> */}
                {settings.content_pages.map((page, index) => (
                  <li key={index}><a className="text-muted cursor-pointer" onClick={(event) => openContentPageModal(page)}>{page.title}</a></li>
                ))}
                </ul>
              }
            </div>
            <ContentPageModal />
            {showContentPageModal && (
              <ContentPageModal
                showModal={showContentPageModal}
                modalDetails={contentPagemodalDetails}
                handleOnModalClose={handleOnCloseContentPageModal}
              />
            )}
            <div className="col-lg-3 col-md-6 col-sm-3 application-link-section">
              <div className="row">
                <a href="#" title="Coming Soon" onClick={(event) => event.preventDefault()}><img src="/images/download-android-app.png"/></a>
              </div>
              <div className="row pt-2">
                <a href="#" title="Coming Soon" onClick={(event) => event.preventDefault()}><img src="/images/download-ios-app.png"/></a>
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 fw-light bg-gray-800 text-gray-300">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 text-center">
              <p className="text-sm mb-md-0">&copy; { new Date().getFullYear() }, {config.COMPANY_NAME}  All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
const mapStateToProps = (state) => {
  return {
    settings: settingsSelector(state),
  };
};
export default connect(mapStateToProps, {})(Footer);