import http from "./axiosConfig";
import { errorMessage } from "utils/ToastNotificationHelper";
import { getUserAuthToken } from "utils/StorageHelper";
import config from "config/config";
class APIService {
	addHeaders(token){
		http.defaults.headers.common['Authorization'] = "Bearer "+token;
		http.defaults.headers.common['X-Language-Id'] = 1;
	}
    async getData(url) {
	    try {
			const token = getUserAuthToken();
			if(token){
				this.addHeaders(token);
			} 
	      	var res = await http.get(url);
	      	if(res){
				return Promise.resolve(res.data);
		  	}
	    } catch (err) {
			console.log(err);
			if(err?.response?.status != 401){
				errorMessage('Something went wrong!');
				return Promise.reject(err);
			}
	    }
  	}
  	async submitData(url,data){
  		try {
			const token = getUserAuthToken();
			if(token){
				this.addHeaders(token);
			} 
			data.country_code = config.DEFAULT_COUNTRY_CODE;
			data.currency_code = config.DEFAULT_CURRENCY_CODE; 
	      	var res = await http.post(url, data);
		  	// if(res){
				return res.data;
		  	// }
	    } catch (err) {
			console.log(err);
			if(err?.response?.status != 401){
				// errorMessage('Something went wrong!');
				// return Promise.reject(err);
				return {
					"status" : false,
					"message": "Something went wrong!"
				};
			}
	    }	
  	}
	async submitFormData(url,data){
		try {
			const token = getUserAuthToken();
			if(token){
				this.addHeaders(token);
			} 
			var res = await http.post(url, data,
				{ headers: {
				"Content-Type": "multipart/form-data",
			  	}}
			  );
			// if(res){
			  return res.data;
			// }
	  } catch (err) {
		  console.log(err);
		  if(err?.response?.status != 401){
			//   errorMessage('Something went wrong!');
			//   return Promise.reject(err);
			return {
				"status" : false,
				"message": "Something went wrong!"
			};
		  }
	  }	
	}
	async getPublicData(url) {
	    try {
			const token = config.PUBLIC_API_TOKEN;
			if(token){
				this.addHeaders(token);
			} 
	      	var res = await http.get(url);
	      	if(res){
				return Promise.resolve(res.data);
		  	}
	    } catch (err) {
			console.log(err);
			if(err?.response?.status != 401){
				return {
					"status" : false,
					"message": "Something went wrong!"
				};
			}
	    }
  	}
	async submitPublicData(url,data){
		try {
			const token = config.PUBLIC_API_TOKEN;
		  	if(token){
			  // http.defaults.headers.common['Authorization'] = "Bearer "+token;
			  // http.defaults.headers.common['X-Language-Id'] = 1;
			  this.addHeaders(token);
		  	}
			data.country_code = config.DEFAULT_COUNTRY_CODE;
			data.currency_code = config.DEFAULT_CURRENCY_CODE; 
			var res = await http.post(url, data);
			// if(res){
			  return res.data;
			// }
	  } catch (err) {
		  console.log(err);
		  if(err?.response?.status != 401){
			  // errorMessage('Something went wrong!');
			  // return Promise.reject(err);
			  return {
				  "status" : false,
				  "message": "Something went wrong!"
			  };
		  }
	  }	
	}
}
export default new APIService();
