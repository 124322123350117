module.exports = {
    API_URL: process.env.REACT_APP_BACKEND_API_URL,
    // API_URL: "https://secretbucks.kriyaninfotech.net/api/v1",
    APP_LOGO: "/images/logo.png",
    COMPANY_NAME: "Kriyan Infotech Pvt. Ltd.",
    LINKEDIN_URL: "https://www.linkedin.com/company/secret-bucks/",
    INSTAGRAM_URL: "https://www.instagram.com/Secret_Bucks/",
    FACEBOOK_URL: "https://www.facebook.com/people/Secret-Bucks/100089286121242/",
    TWITTER_URL: "https://twitter.com/ISecretbucks",
    // GOOGLE_API_KEY: "AIzaSyDNs4VHoYG6QZvJn3thYmcUdsJqp1KpL7g",
    GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    // ahmedabad location
    DEFAULT_LOCATION: {
      lat: 23.0225, 
      lng: 72.5714
    },
    AWS_TEMP_URL_VALIDITY_HOUR:24,
    AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    AWS_REGION: process.env.REACT_APP_AWS_REGION,
    AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
    CRYPTO_SECRET_KEY: "ztohxhkzvgxzjxgyzzmg",
    PUBLIC_API_TOKEN:"UNOdnk7UyXXGgmxTEOmO",
    DEFAULT_COUNTRY_CODE: "IN",
    DEFAULT_CURRENCY_CODE: "INR",
    FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
    FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    SETTINGS_REFRESH_EVERY_MINUTES: 30
  };