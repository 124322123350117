import AWS from "aws-sdk"; 
import LRUCache from 'lru-cache';
import store from 'store';
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_REGION, AWS_TEMP_URL_VALIDITY_HOUR, AWS_BUCKET  } from "config/config";
import { settingsSelector } from "components/_selectors/authSelectors";
import { APP_LOGO } from "config/config";
import { decryptText, encryptText } from "./CommonHelper";
AWS.config.update({accessKeyId: decryptText(AWS_ACCESS_KEY_ID), secretAccessKey: decryptText(AWS_SECRET_ACCESS_KEY), region: AWS_REGION });
// AWS.config.update({accessKeyId: "", secretAccessKey: "", region: "" });
const s3 = new AWS.S3();
const myBucket = AWS_BUCKET
const signedUrlExpireSeconds = 3600 * AWS_TEMP_URL_VALIDITY_HOUR;
// const signedUrlExpireSeconds = 15;
const cache = new LRUCache({
  // max: 100, // Maximum number of items in the cache
  ttl: 3600 * 23, // Maximum age of items (in milliseconds)
  ttlAutopurge: true
});
// cache.purgeStale();
// export const getAwsUrl = (keyFile) => {
//     const url = s3.getSignedUrl('getObject', {
//         Bucket: myBucket,
//         Key: keyFile,
//         Expires: signedUrlExpireSeconds
//     })
//     return url;
// }
export const loadCache = () => {
  const data = localStorage.getItem('imageCache');
  if (data) {
    cache.load(JSON.parse(data));
  }
}
export const saveCache = () => {
  localStorage.setItem('imageCache', JSON.stringify(cache.dump()));
}
// export const updateAWSCredentials = () => {
  
//   try {
//     const { aws_access_key_id, aws_secret_access_key } = settingsSelector(store.getState());
//     console.log({
//       accessKeyId: aws_access_key_id,
//       secretAccessKey: aws_secret_access_key,
//       region:AWS_REGION
//     })
//     // AWS.config.update({
//     //   accessKeyId: aws_access_key_id,
//     //   secretAccessKey: aws_secret_access_key,
//     //   region:AWS_REGION
//     // });
//     AWS.config.update({accessKeyId: AWS_ACCESS_KEY_ID, secretAccessKey: AWS_SECRET_ACCESS_KEY, region: AWS_REGION });
//     console.log("AWS configuration updated:", AWS.config);
//     return true;
//   } catch (error) {
//     console.error("Error fetching AWS credentials from API:", error);
//     return false;
//   }
// };
// export const getAwsUrl = (keyFile) => {
//   const cachedUrl = cache.get(keyFile);
//   if (cachedUrl) {
//     // console.log("retrieved from cache");
//     return cachedUrl;
//   }
//   // set credentials
//   console.log("AWS configuration before update:", AWS.config);
// const isValid = updateAWSCredentials();
// console.log("AWS configuration after update:", AWS.config);
//   if(isValid){
//     const url = s3.getSignedUrl('getObject', {
//         Bucket: myBucket,
//         Key: keyFile,
//         Expires: signedUrlExpireSeconds
//     })
//     cache.set(keyFile, url);
//     return url;
//   }
//   else{
//     return APP_LOGO;
//   }
// }
export const getAwsUrl = (keyFile) => {
  const cachedUrl = cache.get(keyFile);
// console.log(keyFile);
  // console.log(cachedUrl)
  if (cachedUrl) {
    // console.log("retrieved from cache");
    return cachedUrl;
  }
  const url = s3.getSignedUrl('getObject', {
      Bucket: myBucket,
      Key: keyFile,
      Expires: signedUrlExpireSeconds
  })
// console.log("setting cache ****************");
  // console.log(url)
  cache.set(keyFile, url);
  return url;
}
export const purgeCache =() => {
  // del;ete expired cache
  cache.purgeStale();
}
export const resetCache =() => {
  // del;ete expired cache
  cache.clear();
}
export const getCacheList = () => {
  console.log(cache.get('public/upload/vendor/logo/oTAoQdZ3MuISgBpMPdXbpkM2RZXPBU0PvEEcPULX.png'));
  console.log(cache.has('public/upload/vendor/logo/oTAoQdZ3MuISgBpMPdXbpkM2RZXPBU0PvEEcPULX.png'));
  console.log(cache.keys()); // Output an array of all keys in the cache
  console.log(cache.values());
}