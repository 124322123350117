const initialstate = {
  data: []
};

const DashboardReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "DASHBOARD_DATA":
      return {
        users: [action.payload, ...state.data]
      };
    default:
      return state;
  }
};

export default DashboardReducer;
