import React from 'react'
import {useEffect, useState} from "react";
import Navbar from "components/_common/Navbar";
import Header from "components/_common/Header";
import Footer from "components/_common/Footer";
import { Routes, Route, Outlet, Link } from 'react-router-dom';
export default function AppLayout(props) {
  const [loading, setLoading] = useState(false);
    return (
      <div className='main-layout'>
        <Header />
        <div className="page-content">
        <Outlet />
        </div>
        <Footer />
        <button title="Back to Top" type="button" className="btn btn-primary" id='scrollTopbutton'>
          <i className="fas fa-arrow-up"></i>
        </button>
      </div>
    )
}
