import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { mainRoutes, loginRoutes, profileRoutes } from "config/routing";
import { PreloaderComponent } from "components/_common/Preloader";
import NotFound from "components/_common/ErrorPages/NotFound";
import AppLayout from "layouts/AppLayout";
import ProfileLayout from "layouts/ProfileLayout";
export default function AppRouteWrapper() {
  var routeKeys = Object.keys(mainRoutes);
  var loginRouteKeys = Object.keys(loginRoutes);
  var profileRouteKeys = Object.keys(profileRoutes);
  return (
    <Suspense fallback={<PreloaderComponent />}>
      <Routes>
        <Route element={<AppLayout />}>
          {routeKeys.map((routeKey, i) => {
            return (
              <Route
                key={i}
                path={mainRoutes[routeKey].path}
                element={mainRoutes[routeKey].element}
              />
            );
          })}
          <Route element={<ProfileLayout />}>
            {profileRouteKeys.map((routeKey, i) => {
              return (
                <Route
                  key={i}
                  path={profileRoutes[routeKey].path}
                  element={profileRoutes[routeKey].element}
                />
              );
            })}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        {loginRouteKeys.map((routeKey, i) => {
          return (
            <Route
              key={i}
              path={loginRoutes[routeKey].path}
              element={loginRoutes[routeKey].element}
            />
          );
        })}
      </Routes>
    </Suspense>
  );
}
