import { connect, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  profileRoutes as routes,
  mainRoutes,
  loginRoutes,
} from "config/routing";
import { useTranslation } from "react-i18next";
import { logoutDevice } from "store/actions/AuthAction";
import {
  userLocationSelector,
  userInfoSelector,
  settingsSelector,
  userProfileSelector,
} from "components/_selectors/authSelectors";
import { capitalizeWord } from "utils/CommonHelper";
import { getAwsUrl } from "utils/AwsHelper";
import { storeUserInfo } from "utils/StorageHelper";
import { successMessage, errorMessage } from "utils/ToastNotificationHelper";
import { uploadProfile } from "store/actions/AuthAction";
import ImageComponent from "components/_common/ImageComponent";
import ConfirmDialogModal from "components/_common/Modals/ConfirmDialogModal";

function ProfileNavBar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [file, setFile] = useState("/images/default_user_image.png");
  // signout confirmation variables
  const [confirmSignoutModalDetails, setConfirmSignoutModalDetails] = useState(
    {}
  );
  const [showConfirmSignoutModal, setShowConfirmSignoutModal] = useState(false);

  function handleChange(e) {
    if (e.target.files.length > 0) {
      var file = e.target.files[0];
      let formData = new FormData();
      formData.append("photo", file);
      uploadProfile(formData, (success, response) => {
        setLoading(false);
        if (success) {
          var profileInfo = response.data;
          var userInfo = props.userInfo;
          userInfo.profileData = profileInfo;
          storeUserInfo(userInfo);
          setFile(URL.createObjectURL(file));
          dispatch({
            type: "UPDATE_PROFILE_DATA",
            payload: userInfo.profileData,
          });
          // successMessage("Profile photo uploaded successfully");
          successMessage(response.message);
        } else {
          // error
          errorMessage(response.message);
        }
      });
    }
  }
  useEffect(() => {
    if (props.profileData) {
      if (props.profileData.profile_pic) {
        var getProfileImage = getAwsUrl(props.profileData.profile_pic);
        setFile(getProfileImage);
      }
      setUserInfo(props.profileData);
    }
  }, [props.profileData]);
  // signout confirmation
  const handleOnConfirmSignoutModalClose = () => {
    setShowConfirmSignoutModal(false);
  };
  const onConfirmSignout = () => {
    setShowConfirmSignoutModal(false);
    logoutDevice(() => navigate(routes.home.path));
  };
  return (
    <div className="col-lg-3 me-lg-auto">
      <div className="card border-0 shadow mb-6 mb-lg-0">
        {userInfo && Object.keys(userInfo).length > 0 && (
          <div className="card-header bg-gray-100 py-4 border-0 text-center">
            <label
              className="profile-upload d-inline-block cursor-pointer position-relative"
              htmlFor="file-upload"
            >
              <span className="bg-primary border-2 border-white edit-profile-pic">
                <i className="fa fa-camera text-white"></i>
              </span>
              <img
                className="d-none avatar avatar-xxl p-2 mb-2"
                src={file}
                alt=""
                loading="lazy"
              />
              <ImageComponent
                extraClass={"d-block avatar avatar-xxl p-2 mb-2"}
                loadingClass={""}
                showLoading={false}
                src={file}
                alt=""
              />
              <input
                id="file-upload"
                className="file-upload d-none"
                type="file"
                accept="image/*"
                onChange={handleChange}
              />
            </label>
            <div className="d-flex justify-content-center">
              <div>
                <h5>
                  {capitalizeWord(
                    userInfo?.first_name + " " + userInfo?.last_name
                  )}
                </h5>
              </div>
              <div className="icon-circle bg-primary ms-2 mt-1">
                <i className="fa fa-check"></i>
              </div>
            </div>
            {userInfo?.qr_code_image && (
              <img
                className="user-qrcode"
                src={getAwsUrl(userInfo.qr_code_image)}
                loading="lazy"
              />
            )}
          </div>
        )}
        <div className="card-body p-4">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                to={routes.profile.path}
                className={`nav-link custom-link ${
                  pathname === routes.profile.path ? "active" : ""
                }`}
              >
                <div className="nav-div d-flex align-items-center">
                  <div className="icon-rounded icon-rounded-sm bg-primary-light flex-shrink-0 me-2">
                    <i className="fa fa-user"></i>
                  </div>
                  <div>
                    <p className="mb-0">Edit Profile</p>
                  </div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.referEarn.path}
                className={`nav-link custom-link ${
                  pathname === routes.referEarn.path ? "active" : ""
                }`}
              >
                <div className="nav-div d-flex align-items-center">
                  <div className="icon-rounded icon-rounded-sm bg-primary-light flex-shrink-0 me-2">
                    <i className="fa fa-qrcode"></i>
                  </div>
                  <div>
                    <p className="mb-0">Refer & earn</p>
                  </div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.reward.path}
                className={`nav-link custom-link ${
                  pathname === routes.reward.path ? "active" : ""
                }`}
              >
                <div className="nav-div d-flex align-items-center">
                  <div className="icon-rounded icon-rounded-sm bg-primary-light flex-shrink-0 me-2">
                    <i className="fa fa-star"></i>
                  </div>
                  <div>
                    <p className="mb-0">Reward</p>
                  </div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.earnPoint.path}
                className={`nav-link custom-link ${
                  pathname === routes.earnPoint.path ? "active" : ""
                }`}
              >
                <div className="nav-div d-flex align-items-center">
                  <div className="icon-rounded icon-rounded-sm bg-primary-light flex-shrink-0 me-2">
                    <i className="fa fa-tag"></i>
                  </div>
                  <div>
                    <p className="mb-0">Earn Points</p>
                  </div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.myFollowing.path}
                className={`nav-link custom-link ${
                  pathname === routes.myFollowing.path ? "active" : ""
                }`}
              >
                <div className="nav-div d-flex align-items-center">
                  <div className="icon-rounded icon-rounded-sm bg-primary-light flex-shrink-0 me-2">
                    <i className="fa fa-users"></i>
                  </div>
                  <div>
                    <p className="mb-0">My Followings</p>
                  </div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.manageDevice.path}
                className={`nav-link custom-link ${
                  pathname === routes.manageDevice.path ? "active" : ""
                }`}
              >
                <div className="nav-div d-flex align-items-center">
                  <div className="icon-rounded icon-rounded-sm bg-primary-light flex-shrink-0 me-2">
                    <i className="fa fa-mobile"></i>
                  </div>
                  <div>
                    <p className="mb-0">Manage Devices</p>
                  </div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routes.notificationSetting.path}
                className={`nav-link custom-link ${
                  pathname === routes.notificationSetting.path ? "active" : ""
                }`}
              >
                <div className="nav-div d-flex align-items-center">
                  <div className="icon-rounded icon-rounded-sm bg-primary-light flex-shrink-0 me-2">
                    <i className="fa fa-bell"></i>
                  </div>
                  <div>
                    <p className="mb-0">Notification settings</p>
                  </div>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link custom-link cursor-pointer`}
                onClick={(event) => {
                  setConfirmSignoutModalDetails({
                    title: "Confirm",
                    message: "Are you sure you want to sign out ?",
                  });
                  setShowConfirmSignoutModal(true);
                }}
                /* onClick={(event) =>
                  alert(mainRoutes.home.path)
                } */
              >
                <div className="nav-div d-flex align-items-center">
                  <div className="icon-rounded icon-rounded-sm bg-primary-light flex-shrink-0 me-2">
                    <i className="fas fa-sign-out-alt"></i>
                  </div>
                  <div>
                    <p className="mb-0">Sign Out</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          {showConfirmSignoutModal && (
            <ConfirmDialogModal
              showModal={showConfirmSignoutModal}
              modalDetails={confirmSignoutModalDetails}
              onConfirmFollowVendor={onConfirmSignout}
              handleOnModalClose={handleOnConfirmSignoutModalClose}
            />
          )}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    currentLocation: userLocationSelector(state),
    userInfo: userInfoSelector(state),
    settings: settingsSelector(state),
    profileData: userProfileSelector(state),
  };
};
export default connect(mapStateToProps, {})(ProfileNavBar);
