import React, { useEffect, useState } from "react";
// import InfiniteScroll from "react-infinite-scroller";
import { getMyNotifications } from "store/actions/AuthAction";
import { getAwsUrl } from "utils/AwsHelper";
import { dateDifferenceCalculation } from "utils/DateHelper";
import { groupArrayData } from "utils/CommonHelper";
import { errorMessage } from "utils/ToastNotificationHelper";
import { Preloader } from "../Preloader";
import LoadMoreScroll from "../LoadMoreScroll";
import ImageComponent from "../ImageComponent";

export default function Notifications(props) {
  const { showNotification } = props;
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalFetchedData, setTotalFetchedData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [groupNotificationList, setGroupNotificationList] = useState([]);
  // useEffect(() => {
  //   if(showModal){
  //     console.log(modalDetails);
  //     loadData(1);
  //   }
  // },[showModal])
  useEffect(() => {
    loadData(1);
  }, []);
  useEffect(() => {
    if(showNotification){
      loadData(1);
    }
  }, [showNotification]);
  
  const loadData = (page) => {
    // get data based on pagination
    if (!loading) {
      setLoading(true);
      setPage(page);
      var params = {
        limit: limit,
        page: page,
      };
      getMyNotifications(params, (success, response) => {
        setLoading(false);
        setFetchedData(true);
        if (success) {
          // console.log(response.data)
          if(page == 1){
            setNotificationList(response.data.data);
            setTotalFetchedData(response.data.data.length);
            setGroupNotificationList(
              groupArrayData(response.data.data)
            );
          }else{
            setNotificationList([...notificationList, ...response.data.data]);
            setTotalFetchedData(totalFetchedData + response.data.data.length);
            setGroupNotificationList(
              groupArrayData([...notificationList, ...response.data.data])
            );
          }
          setTotalRecords(response.data.total);
        } else {
          // error
          errorMessage(response.message);
        }
      });
    }
  };
  const getPaginatedData = () => {
    // get data on click of pagination link
    loadData(page + 1);
  };
  return (
    <div
      className="dropdownNotificationMenuBlock dropdown-menu dropdown-menu-end"
      aria-labelledby="dropdownMenuButton"
    >
      {/* px-3 */}
      <div className="notification-dropdown-menu  me-2">
        <LoadMoreScroll
          loading={loading}
          totalRecords={totalRecords}
          getPaginatedData={getPaginatedData}
          fetchedData={fetchedData}
          mainElementExtraClass={"px-3 custom-scrollbar model-lazy-load"}
          page={page}
          emptyMsg={"No Notifications Yet"}
          preloaderExtraClass={"mt-5"}
          totalFetchedData={totalFetchedData}
          height={"300px"}
        >
          {groupNotificationList &&
            groupNotificationList.map((notificationList, index) => {
              return (
                <React.Fragment key={index}>
                  <div>
                    <span className="d-none">{notificationList.date}</span>
                    <span className="text-uppercase text-muted small">
                      {dateDifferenceCalculation(notificationList.date)}
                    </span>
                  </div>
                  {notificationList.list.map((list, listIndex) => {
                    return (
                      <div
                        className="align-items-center d-block d-flex d-sm-flex mb-3 p-3 rounded-3 shadow"
                        key={listIndex}
                      >
                        <span className="d-none">{list.timestamp}</span>
                        <div className="flex-shrink-0 me-3 text-md-center text-primary">
                          <img
                            className="avatar avatar-border-white d-none"
                            src={getAwsUrl(list.logo)}
                            alt={list.title}
                          />
                          <ImageComponent
                            extraClass={"avatar avatar-border-white"}
                            loadingClass={"avatar avatar-border-white"}
                            showLoading={false}
                            src={getAwsUrl(list.logo)}
                            alt={list.title}
                          />
                        </div>
                        <div>
                          <h6 className="mb-1 mt-2 text-primary">
                            {list.title}
                          </h6>
                          <p className="text-muted text-sm">{list.message}</p>
                        </div>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </LoadMoreScroll>
        {/* <InfiniteScroll
              pageStart={0}
              loadMore={getPaginatedData}
              hasMore={!(totalFetchedData == totalRecords)}
              loader={<Preloader key={0} height={"50px"} extraClass={"px-7 pt-3 mb-4"}/>}
              useWindow={false}
          >
            {(fetchedData && totalRecords == 0) &&
                <h6 className='p-6 text-center'>No Notifications Yet</h6> 
            }
            { (page == 1) && loading &&
              <Preloader height={"50px"} extraClass={"p-7"}/>
            }
            {groupNotificationList && groupNotificationList.map((notificationList,index) => {
                  return (
                      <React.Fragment key={index}>
                          <div>
                            <span className="d-none">{ notificationList.date}</span>
                            <span className="text-uppercase text-muted small">{ dateDifferenceCalculation(notificationList.date) }</span>
                          </div>
                          {notificationList.list.map((list, listIndex) => {
                              return (
                                <div className="align-items-center d-block d-flex d-sm-flex mb-3 p-3 rounded-3 shadow" key={listIndex}>
                                  <span className="d-none">{ list.timestamp}</span>
                                  <div className="flex-shrink-0 me-3 text-md-center text-primary">
                                    <img
                                      className="avatar avatar-border-white"
                                      src={getAwsUrl(list.logo)}
                                      alt={list.title}
                                    />
                                  </div>
                                  <div>
                                    <h6 className="mb-1 mt-2 text-primary">{list.title}</h6>
                                    <p className="text-muted text-sm">
                                      {list.message}
                                    </p>
                                  </div>
                                </div>
                              );
                          })}
                      </React.Fragment>
                  )
              })
            }
          </InfiniteScroll> */}
      </div>
    </div>
  );
}
