const endpoints = {
    // dahsboard/home, offer, vendor and outlet module
    "publicDashboard" : { url: '/public/dashboard', method: 'post' },
    "publicOfferList" : { url: '/public/offers', method: 'post' },
    "publicOfferDetail" : { url: '/public/offer-detail', method: 'post' },
    "publicVendorList" : { url: '/public/vendor', method: 'post' },
    "publicSimilarVendorList" : { url: '/public/similar-vendor', method: 'post' },
    "publicVendorDetail" : { url: '/public/vendor-detail', method: 'post' },
    "publicOutletList" : { url: '/public/outlet', method: 'post' },
    "publicOutletDetail" : { url: '/public/outlet-detail', method: 'post' },
    "publicProductList" : { url: '/public/products', method: 'post' },
    "publicPhotoList" : { url: '/public/photos', method: 'post' },
    "publicAllRatingReview" : { url: '/public/all-ratings', method: 'post' },
    "categoryList": { url: '/industry-category', method: 'get' },
    // offer, vendor and outlet module api for logged in user
    "offerList" : { url: '/offers', method: 'post' },
    "vendorList" : { url: '/vendor', method: 'post' },
    "outletList" : { url: '/outlet', method: 'post' },
    "offerDetail" : { url: '/offer-detail', method: 'post' },
    "vendorDetail" : { url: '/vendor-detail', method: 'post' },
    "outletDetail" : { url: '/outlet-detail', method: 'post' },
    "productList" : { url: '/products', method: 'post' },
    "photoList" : { url: '/photos', method: 'post' },
    "submitRatingReview" : { url: '/rating', method: 'post' },
    "allRatingReview" : { url: '/all-ratings', method: 'post' },
    // setting API
    "settings" : { url: '/settings', method: 'get' },
    // login and profile module
    "login" : { url: '/login', method: 'post' },
    "verifyUser" : { url: '/verification', method: 'post' },
    "resendOTP" : { url:'/resend-otp', method: 'post'},
    "refreshToken" : { url:'/refresh-token', method: 'post'},
    "refreshFirebaseToken" : { url:'/refresh-firebase-token', method: 'post'},
    "uploadProfile" : { url: '/update-profile-photo', method: 'post' },
    "updateProfile" : { url: '/update-profile', method: 'post' },
    "logoutDevice" : { url: '/logout-my-devices', method: 'post' },
    "myDevice" : { url: '/my-devices', method: 'get' },
    "myFollowingVendor" : { url: '/my-vendors', method: 'get' },
    "myNotifications" : { url: '/notifications', method: 'post' },
    "getNotificationSettings" : { url: '/get-notification-settings', method: 'get' },
    "updateNotificationSettings" : { url: '/manage-notification', method: 'post' },
    "myRewardList" : { url: '/transaction-dashboard', method: 'post' },
    "getTransaction" : { url: '/transaction', method: 'post' },
    "followVendor" : { url: '/follow', method: 'post' },
}
export default endpoints;