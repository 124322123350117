import { encryptObject, decryptObject } from "./CommonHelper";
import { getCurrentDateTime } from "./DateHelper";
export const storeLocalStorage = (key,value) => {
    localStorage.setItem(key,value);
}

export const getLocalStorage = (key) => {
    return localStorage.getItem(key);
}

export const getUserInfo = () => {
    var userInfo = localStorage.getItem("userInfo");
    if(userInfo){
        userInfo = decryptObject(userInfo);
    }
    return userInfo;
}

export const storeUserInfo = (obj) => {
    var encryptedObj = encryptObject(obj);
    storeLocalStorage("userInfo",encryptedObj);
}

export const getSettingInfo = () => {
    var settingInfo = getLocalStorage("appSettings");
    if(settingInfo){
        settingInfo = decryptObject(settingInfo);
    }
    return settingInfo;
}
export const storeSettingInfo = (obj) => {
    obj = {...obj, ...{"last_updated_at": getCurrentDateTime()}}
    var encryptedObj = encryptObject(obj);
    storeLocalStorage("appSettings",encryptedObj);
}

export const getUserAuthToken = (key) => {
    var userInfo = getUserInfo();
    var authorization = "";
    if(userInfo){
        authorization = userInfo.authorization;
    }
    return authorization;
}
export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
}