import { userCurrencySelector } from "components/_selectors/authSelectors";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { NumericFormat } from "react-number-format";
import { connect } from "react-redux";
import { getAwsUrl } from "utils/AwsHelper";
import ImageComponent from "../ImageComponent";
import Readmore from "../Readmore";

function ProductDetailModal(props) {
  const { showModal, handleOnModalClose, modelDetails, currency } = props;
  return (
    <Modal
      show={showModal}
      onHide={handleOnModalClose}
      id="productdetailModal"
      size="lg"
      scrollable={true}
      centered
    >
      <Modal.Header closeButton className="border-0">
        <h6 className="modal-title text-uppercase" id="productdetailModalLabel">
          Product Detail
        </h6>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="border-0 card">
            <div className="row">
              <div className="col-lg-6">
                { /* <!--- image,free products and offers --> */}
                <div className="d-flex flex-column justify-content-center">
                  <div className="main_image product-detail-image">
                    <img
                      src={getAwsUrl(modelDetails.featured_image)}
                      alt={modelDetails.name}
                      className="img-fluid d-none"
                      loading="lazy"
                    />
                    <ImageComponent
                          src={getAwsUrl(modelDetails.featured_image)}
                          extraClass={"img-fluid"}
                          loadingClass={"img-fluid"}
                          showLoading={false}
                          alt={modelDetails.name}
                        />
                  </div>
                </div>
                {modelDetails.free_product &&
                  modelDetails.free_product.length > 0 && (
                    <div className="mt-4">
                      <h5 className="border-bottom pb-2 text-gray-700">
                        Get Free
                      </h5>
                      {modelDetails.free_product.map((details, index) => (
                        <div className="align-items-center border d-block d-flex d-sm-flex mb-2 p-2 rounded-3 shadow">
                          <div className="flex-shrink-0 me-3 text-md-center text-primary">
                            <img
                              className="avatar avatar-border-white d-none"
                              src={getAwsUrl(
                                details.free_product_info?.featured_image
                              )}
                              alt={details.free_product_info?.name}
                              loading="lazy"
                            />
                            <ImageComponent
                              src={getAwsUrl(
                                details.free_product_info?.featured_image
                              )}
                              extraClass={"avatar avatar-border-white"}
                              loadingClass={"avatar avatar-border-white"}
                              showLoading={false}
                              alt={details.free_product_info?.name}
                            />
                          </div>
                          <div>
                            <p className="mb-0 text-sm">
                              {details.free_product_info?.name}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                <div className="mt-4">
                  <h5 className="border-bottom pb-2 text-gray-700">Offers</h5>
                  <div className="align-items-center d-flex">
                    <i className="fa fa-tag me-2 text-primary"></i>
                    <div>Earn Loyalty Points</div>
                    <div className="ms-auto">
                      <NumericFormat
                        className={`num-format`}
                        value={modelDetails.loyalty_point}
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                { /* description with reamore functionality */ }
                <div className="p-lg-3 pt-0 pt-3 pt-lg-0 right-side">
                  <div className={`pro-description `}>
                    <div className="align-items-baseline d-flex justify-content-between">
                      <h5 className="text-primary" title={modelDetails.name}>
                        {modelDetails.name}
                      </h5>
                    </div>
                    <h6 className="text-gray-700">
                      <NumericFormat
                        className={`num-format`}
                        value={modelDetails.price}
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={currency+" "}
                        fixedDecimalScale={true}
                      />
                    </h6>
                    <div className="mt-2 pr-3 content">
                      <p
                        className="d-none"
                        title={modelDetails.about_product}
                        dangerouslySetInnerHTML={{
                          __html: modelDetails.about_product.replace(
                            /\n/g,
                            "<br />"
                          ),
                        }}
                      ></p>
                      <Readmore
                        charLimit={550}
                        text={modelDetails.about_product.replace(
                          /\n/g,
                          "<br />"
                        )}
                      />
                    </div>
                  </div>
                  <div className="mt-4 d-none">
                    <h5 className="border-bottom pb-2 text-gray-700">Offers</h5>
                    <div className="align-items-center d-flex">
                      <i className="fa fa-tag me-2 text-primary"></i>
                      <div>Earn Loyalty Points</div>
                      <div className="ms-auto">
                        <NumericFormat
                          className={`num-format`}
                          value={modelDetails.loyalty_point}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const  mapStateToProps = (state) => {
  return {
    currency: userCurrencySelector(state)
  }
};
export default connect(mapStateToProps,{ })(ProductDetailModal);