import React, { useEffect, useState } from 'react';
import Spinner from "assets/images/svg/preloader.svg";
import "../Preloader/loader.css";
export default function ImageComponent({ src, extraClass="", loadingClass="", showLoading, ...restProps }) {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setIsLoaded(true);
    img.onerror= (e) => handleOnError(e);
  }, [src]);
  const handleOnError = (e) => {
    e.target.src =
      "/images/foodbg.png";
}
  if (!isLoaded && showLoading) {
    return <div className={`shimmer align-items-center d-flex justify-content-center ${loadingClass}`}>
      <img src={src} className={`img-component ${extraClass}`} {...restProps} style={{ "opacity":0 }}/>
      </div>;
  }

  return <img src={src} className={`${(!isLoaded && showLoading) ?"shimmer":""} img-component ${extraClass}`} {...restProps} />;
}