import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  mainRoutes as routes,
  loginRoutes,
  profileRoutes,
} from "config/routing";
import { useTranslation } from "react-i18next";
import Notifications from "./Notifications";
import CardDetails from "./CardDetails";
import { getUserAuthToken } from "utils/StorageHelper";
import { logoutDevice } from "store/actions/AuthAction";
import {
  userLocationSelector,
  userInfoSelector,
  settingsSelector,
  userProfileSelector,
} from "components/_selectors/authSelectors";
import Toast from "react-bootstrap/Toast";
import { getAwsUrl } from "utils/AwsHelper";
import ImageComponent from "../ImageComponent";
import { onMessageListener } from "services/firebase.service";
import config from "config/config";
import ConfirmDialogModal from "../Modals/ConfirmDialogModal";
const Navbar = ({ userInfo, profileData }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userToken = getUserAuthToken();
  const [loggedin, setisLoggedin] = useState(false);
  const [file, setFile] = useState("/images/default_user_image.png");
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  // signout confirmation variables
  const [confirmSignoutModalDetails, setConfirmSignoutModalDetails] = useState(
    {}
  );
  const [showConfirmSignoutModal, setShowConfirmSignoutModal] = useState(false);

  useEffect(() => {
    if (userInfo?.authorization) {
      setisLoggedin(true);
    } else {
      setisLoggedin(false);
    }
  }, [userInfo]);
  useEffect(() => {
    if (profileData) {
      if (profileData.profile_pic) {
        var getProfileImage = getAwsUrl(profileData.profile_pic);
        setFile(getProfileImage);
      }
    }
  }, [profileData]);
  // notification listener
  onMessageListener()
    .then((payload) => {
      setShowNotification(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));
  // signout confirmation
  const handleOnConfirmSignoutModalClose = () => {
    setShowConfirmSignoutModal(false);
  };
  const onConfirmSignout = () => {
    setShowConfirmSignoutModal(false);
    logoutDevice(() => navigate(routes.home.path));
  };
  return (
    <>
      {/* notification display start */}
      <div
        style={{ position: "absolute", top: 0, right: 0, minWidth: "260px" }}
      >
        <Toast
          onClose={() => setShowNotification(false)}
          show={showNotification}
          delay={4000}
          autohide
          animation
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            maxWidth: 310,
          }}
        >
          <Toast.Header>
            <img
              src={config.APP_LOGO}
              className="rounded-4 notification-logo me-1"
              alt={t("app_name")}
            />
            <strong className="me-auto notification-header-title">
              {notification.title}
            </strong>
            <small>{t("now")}</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
      </div>
      {/* notification display end */}
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav ms-auto align-items-lg-center navmenu-section">
          <li className="nav-item nav-item-setion">
            <Link
              to={routes.vendorList.path}
              className={`nav-link nav-animation ${
                pathname === routes.vendorList.path ? "active" : ""
              }`}
            >
              {t("brands")}
            </Link>
          </li>
          <li className="nav-item nav-item-setion">
            <Link
              to={routes.offerList.path}
              className={`nav-link nav-animation ${
                pathname === routes.offerList.path ? "active" : ""
              }`}
            >
              {t("offers")}
            </Link>
          </li>
          {!loggedin && (
            <>
              <li className="nav-item nav-item-setion">
                {/* <Link
                  to={loginRoutes.signin.path}
                  className={`nav-link ${
                    pathname === loginRoutes.signin.path ? "active" : ""
                  }`}
                >
                  {t("signin")}
                </Link> */}
                <Link
                  to={loginRoutes.signin.path}
                  className={`btn btn-primary btn-sm rounded-pill btn-signin ${
                    pathname === loginRoutes.signin.path ? "active" : ""
                  }`}
                >
                  {t("signin")}
                </Link>
              </li>
            </>
          )}
          {loggedin && (
            <>
              <li className="nav-item nav-animation nav-item-setion">
                <Link
                  to={profileRoutes.referEarn.path}
                  className={`nav-link ${
                    pathname === profileRoutes.referEarn.path ? "active" : ""
                  }`}
                >
                  {t("refer & earn")}
                </Link>
              </li>
              <li className="nav-item-setion nav-item-icon dropdown dropdownNotificationMenuSection">
                <a
                  className="position-relative text-gray-400 py-2 px-1 me-3 text-decoration-none"
                  id="dropdownNotificationMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  href="#"
                >
                  <i className="fa fa-bell"></i>
                  <span className="notification-badge notification-badge-number bg-primary d-none">
                    5
                  </span>
                </a>
                <Notifications showNotification={showNotification} />
              </li>
              <li className="nav-item-icon dropdown dropdownCardMenuSection">
                {/* data-bs-toggle="dropdown" */}
                <a
                  className="cursor-pointer position-relative text-gray-400 py-2 px-1 me-3 text-decoration-none"
                  id="dropdownCardMenuButton"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg className="svg-icon svg-icon svg-icon-light w-20px h-20px text-gray-400 flex-shrink-0">
                    <use xlinkHref="#bank-cards-1"> </use>
                  </svg>
                </a>
                <CardDetails />
              </li>
              <li className="nav-item nav-item-icon dropdown ms-lg-3">
                <a
                  id="userDropdownMenuLink"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className="avatar avatar-sm avatar-border-white me-2 d-none"
                    src={file}
                    alt=""
                    loading="lazy"
                  />
                  <ImageComponent
                    extraClass={"avatar avatar-sm avatar-border-white me-2"}
                    loadingClass={"avatar avatar-sm avatar-border-white me-2"}
                    showLoading={false}
                    src={file}
                    alt={""}
                  />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="userDropdownMenuLink"
                >
                  <Link
                    to={profileRoutes.profile.path}
                    className={`dropdown-item ${
                      pathname === profileRoutes.profile.path ? "active" : ""
                    }`}
                  >
                    Profile
                  </Link>
                  <Link
                    to={profileRoutes.reward.path}
                    className={`dropdown-item ${
                      pathname === profileRoutes.reward.path ? "active" : ""
                    }`}
                  >
                    Reward
                  </Link>
                  <Link
                    to={profileRoutes.earnPoint.path}
                    className={`dropdown-item ${
                      pathname === profileRoutes.earnPoint.path ? "active" : ""
                    }`}
                  >
                    Earn Points
                  </Link>
                  <Link
                    to={profileRoutes.myFollowing.path}
                    className={`dropdown-item ${
                      pathname === profileRoutes.myFollowing.path
                        ? "active"
                        : ""
                    }`}
                  >
                    My Followings
                  </Link>
                  <Link
                    to={profileRoutes.manageDevice.path}
                    className={`dropdown-item ${
                      pathname === profileRoutes.manageDevice.path
                        ? "active"
                        : ""
                    }`}
                  >
                    Mange Devices
                  </Link>
                  <Link
                    to={profileRoutes.notificationSetting.path}
                    className={`dropdown-item ${
                      pathname === profileRoutes.notificationSetting.path
                        ? "active"
                        : ""
                    }`}
                  >
                    Notification Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <a
                    className="dropdown-item cursor-pointer"
                    onClick={(event) => {
                      setConfirmSignoutModalDetails({
                        title: "Confirm",
                        message: "Are you sure you want to sign out ?",
                      });
                      setShowConfirmSignoutModal(true);
                    }}
                  >
                    <i className="fas fa-sign-out-alt me-2 text-muted"></i> Sign
                    out
                  </a>
                </div>
              </li>
            </>
          )}
        </ul>
        {showConfirmSignoutModal && (
          <ConfirmDialogModal
            showModal={showConfirmSignoutModal}
            modalDetails={confirmSignoutModalDetails}
            onConfirmFollowVendor={onConfirmSignout}
            handleOnModalClose={handleOnConfirmSignoutModalClose}
          />
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    currentLocation: userLocationSelector(state),
    userInfo: userInfoSelector(state),
    settings: settingsSelector(state),
    profileData: userProfileSelector(state),
  };
};
export default connect(mapStateToProps, {})(Navbar);
