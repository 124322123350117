import React from "react";
import Spinner from "assets/images/svg/preloader.svg";
import "./loader.css";
export function PreloaderComponent() {
  return (
    <section
      style={{
        height: "100vh",
        width: "100%",
        padding: "calc(250px + 1.5625vw) 0",
        textAlign: "center",
      }}
    >
      <img
        style={{
          height: "50px",
          width: "50px",
          margin: "0 auto",
        }}
        src={Spinner}
        alt="Loading"
        draggable={false}
      />
    </section>
  );
}

// export function Preloader({ withMargin, height, margin, extraClass }) {
//   if (withMargin) {
//     return (
//       <div
//         style={{
//           margin: "0 auto"
//         }}
//       >
//         <img
//           style={{
//             height: height ? height : "100px",
//             margin: margin ? margin : "none"
//           }}
//           src={Spinner}
//           alt="Loading"
//         />
//       </div>
//     );
//   }

//   return (
//     <div className={`d-flex justify-content-center h-100 w-100 ${(extraClass) ? extraClass:''}`}>
//       <img
//         style={{
//           height: height ? height : "100px",
//           margin: margin ? margin : "none"
//         }}
//         src={Spinner}
//         alt="Loading"
//       />
//     </div>
//   );
// }
export function Preloader({ withMargin, height, margin, extraClass }) {
  return (
    <div className={`d-flex justify-content-center align-items-center ${(extraClass) ? extraClass:''}`} style={{ 'height': '30em' }}>
      <span className="loader" />
    </div>
  );
}

export function PreloaderLoadMore({ withMargin, height, margin, extraClass }) {
  return (
    <div className={`d-flex justify-content-center align-items-center ${(extraClass) ? extraClass:''}`}>
      <span className="loader" />
    </div>
  );
}
