import store from 'store';
import APIService from "services/api.service";
import api from "config/api";
import { successMessage, errorMessage } from 'utils/ToastNotificationHelper';
import { removeLocalStorage, getSettingInfo, storeSettingInfo } from 'utils/StorageHelper';
import { userInfoSelector } from 'components/_selectors/authSelectors';
// import { confirmAlert } from 'react-confirm-alert';
import { getUserInfo, storeUserInfo } from 'utils/StorageHelper';
import { SETTINGS_REFRESH_EVERY_MINUTES } from 'config/config';
import { dateDifferenceMinutes } from 'utils/DateHelper';
export const loginUser = async (data,callback) => {
  const response = await APIService.submitData(api.login.url, data);
  if(response){
	  callback(response.status,response);
  }
};
export const logoutDevice = async (callback, isManageDevice=false, deviceId="") => {
  var userInfo = userInfoSelector(store.getState());
  var data = {
    devices : [(deviceId)?deviceId:userInfo.deviceId]
  }
  const response = await APIService.submitData(api.logoutDevice.url, data);
  if(response){
    if(response.status){
      successMessage("You have sign out successfully");
      if(!isManageDevice){
        removeLocalStorage("userInfo");
        store.dispatch({ 
          type: 'UPDATE_USER_DETAILS',
          payload: {} 
        })
      }
      callback();
    }
    else{
      errorMessage(response.message)
    }
  }
};
export const uploadProfile = async (formData, callback) => {
  const response = await APIService.submitFormData(api.uploadProfile.url, formData);
  if(response){
	  callback(response.status,response);
  }
};
export const resendOTP = async (callback) => {
  const response = await APIService.getData(api.resendOTP.url);
  if(response){
	  callback(response.status,response);
  }
};
export const verifyUser = async (data,callback) => {
  const response = await APIService.submitData(api.verifyUser.url, data);
  if(response){
	  callback(response.status,response);
  }
};
export const refreshToken = async (callback) => {
  const response = await APIService.submitData(api.refreshToken.url,{});
  if(response){
    if(response.status){
      var obj = getUserInfo();
      obj.authorization = response.data?.authToken;
      storeUserInfo(obj);
      callback();
    }else{
      // console.log("error");
    }
  }
};
export const refreshFirebaseToken = async (data) => {
  const response = await APIService.submitData(api.refreshFirebaseToken.url, data);
  // console.log(response);
  if(response){
    if(response.status){
      var obj = getUserInfo();
      obj.fcmToken = response.data?.fcmToken;
      storeUserInfo(obj);
    }else{
      // console.log("error");
    }
  }
};
export const updateProfile = async (data,callback) => {
  const response = await APIService.submitData(api.updateProfile.url, data);
  if(response){
	  callback(response.status,response);
  }
};
export const getCurrentLocation = () => {
  if (navigator.geolocation) {
    // console.log("location permission");
    navigator.geolocation.getCurrentPosition(successLocationFunction, errorLocationFunction);
  } else {
    // console.log("location not supported");
    // alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
  }
}
function successLocationFunction(position) {
  // console.log("location getting.........");
    var lat = position.coords.latitude;
    var long = position.coords.longitude;
    store.dispatch({ 
      type: 'UPDATE_USER_LOCATION',
      payload: {
        lat: lat,
        lng: long
      } 
    })
    // console.log('Your latitude is :'+lat+' and longitude is '+long);
}
function errorLocationFunction(error) {
    // console.log("error for location......");
    // console.log(error);
    // alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
}
export const getMyDevices = async (callback) => {
  const response = await APIService.getData(api.myDevice.url);
  if(response){
	  callback(response.status,response);
  }
};
export const getMyFollowingVendor = async (params, callback) => {
  const response = await APIService.submitData(api.myFollowingVendor.url, params);
  if(response){
	  callback(response.status,response);
  }
};
export const getNotificationSettings = async (callback) => {
  const response = await APIService.getData(api.getNotificationSettings.url);
  if(response){
	  callback(response.status,response);
  }
};
export const updateNotificationSettings = async (params, callback) => {
  const response = await APIService.submitData(api.updateNotificationSettings.url, params);
  if(response){
	  callback(response.status,response);
  }
};

export const followVendor = async (params,callback) => {
  const response = await APIService.submitData(api.followVendor.url, params);
  if(response){
	  callback(response.status,response);
  }
};
export const getMyRewards = async (params, callback) => {
  const response = await APIService.submitData(api.myRewardList.url, params);
  if(response){
	  callback(response.status,response);
  }
};
export const getTransactions = async (params, callback) => {
  // get all transcation or by vendor wise
  const response = await APIService.submitData(api.getTransaction.url, params);
  if(response){
	  callback(response.status,response);
  }
};
export const getFreeProduct = (freeList) => {
  var finalFreeList = [];
  if(freeList.cart_item_with_history){
      finalFreeList = freeList.cart_item_with_history.filter((list) => {
          if (list.free_product_history.length > 0) {
              return list;
          }
      });
  }
  return finalFreeList;
}
export const getMyNotifications = async (data, callback) => {
  const response = await APIService.submitData(api.myNotifications.url, data);
  if(response){
      callback(response.status,response);
  }
};

export const getSettings = async () => {
  const response = await APIService.getPublicData(api.settings.url);
  var data = null;
  if(response){
      if(response.status){
        // success
        data = response.data;
        storeSettingInfo(data);
        updateSettingsState(data);
      }
  }
  return data;
};


export const manageSettings = async() => {
  // update settings every VENDOR_INFO_REFRESH_EVERY_MINUTES 
  const settingInfo = getSettingInfo();
  var allowApiCall = true;
  if(settingInfo){
    var minutes = dateDifferenceMinutes(new Date(), settingInfo.last_updated_at);
    if(settingInfo.last_updated_at && minutes <= SETTINGS_REFRESH_EVERY_MINUTES){
      updateSettingsState(settingInfo);
      allowApiCall = false;
      return settingInfo;
    } 
  }
  if(allowApiCall){
    // need to fetch latest info
    var response = getSettings();
    return response;
  }
}
export const updateSettingsState = () => {
  const settings = getSettingInfo();
  store.dispatch({
    type: "UPDATE_SETTINGS",
    payload: settings,
  });
}