import CryptoJS from 'crypto-js'
import { CRYPTO_SECRET_KEY } from 'config/config'
import moment from 'moment';
export const encryptText = (message) => {
    message = message.toString();
    const cipherText = CryptoJS.AES.encrypt(message, CRYPTO_SECRET_KEY);
    return encodeURIComponent(cipherText.toString());
}
export const decryptText = (message) => {
    var bytes = CryptoJS.AES.decrypt(decodeURIComponent(message), CRYPTO_SECRET_KEY)
    return bytes.toString(CryptoJS.enc.Utf8)
}
export const encryptObject = (obj) => {
  obj = JSON.stringify(obj);
  return CryptoJS.AES.encrypt(obj, CRYPTO_SECRET_KEY).toString();
}
export const decryptObject = (obj) => {
  return JSON.parse(CryptoJS.AES.decrypt(obj, CRYPTO_SECRET_KEY).toString(CryptoJS.enc.Utf8));
}
export const getQueryParams = (url) => {
  if(url.indexOf('?') == -1){
    return {};
  }
  const paramArr = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};
  paramArr.map(param => {
      const [key, val] = param.split('=');
      var queryval = val.replace(/\+/g, '%20');
      params[key] = decodeURIComponent(queryval);
  })
  return params;
}
export const placeParams = (pathRegex, params) => {
  const segments = pathRegex.split("/");
  return (
    segments
    .map((segment) => {
      let offset = segment.indexOf(":?") + 1;

      if (offset) {
        let key = segment.slice(offset + 1);
        return params[key];
      }

      offset = segment.indexOf(":") + 1;
      if (!offset) return segment;

      let key = segment.slice(offset);
      return params[key];
    })
    .join("/")
    // Remove trailing "/"
    .replace(/\/+$/, "")
    // Remove redundant "/"
    .replace(/\/{2,}/, "/")
  );
}

export const generateRandomNumber = () => parseInt(Date.now() * Math.random());

export const capitalizeWord = (text) => {
  const words = text.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");;
}

export const groupArrayData = (list) => {
  // this gives an object with dates as keys
  const groups = list.reduce((groups, item) => {
    // const date = item.timestamp.split('T')[0];
    const date = moment(item.timestamp).format('YYYY-MM-DD');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});
  // console.log(groups);
  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date:groups[date][0].timestamp,
      // date:date,
      list: groups[date]
    };
  });
  return groupArrays;
}

export const scrollToTop = () => {
  // called for pagination and filter
  if(!document.querySelector('.has-filter') || (document.querySelector('.has-filter') && document.documentElement.scrollTop > 300)){
    console.log(document.documentElement.scrollTop);
    document.documentElement.scrollTo(0, 0);
    if(document.querySelector('.list-section.overflow-scroll-section')){
      document.querySelector('.list-section.overflow-scroll-section').scrollTop = 0;
    }
  }
}
export const routeScrollToTop = () => {
  // called on route change
  // document.documentElement.scrollTo(0, 0);
  // window.scrollTo(0, 0);
  window.$('html, body').animate({scrollTop:0}, '300');
}

// export const numberFormatShort = ( numberVal, $precision = 0) =>
// {
//   var numberVal_format, suffix;
//     if (numberVal < 999) {
//         // 0 - 999
//         numberVal_format = Number((numberVal).toFixed($precision)).toLocaleString();
//         suffix = '';
//     } else if (numberVal < 900000) {
//         // 0.9k-850k
//         numberVal_format = Number((numberVal).toFixed($precision)).toLocaleString();
//         suffix = 'K';
//     } else if (numberVal < 900000000) {
//         // 0.9m-850m
//         numberVal_format = Number((numberVal).toFixed($precision)).toLocaleString();
//         suffix = 'M';
//     } else if (numberVal < 900000000000) {
//         // 0.9b-850b
//         numberVal_format = Number((numberVal).toFixed($precision)).toLocaleString();
//         suffix = 'B';
//     } else {
//         // 0.9t+
//         numberVal_format = Number((numberVal).toFixed($precision)).toLocaleString();
//         suffix = 'T';
//     }
//     return numberVal_format+suffix;
// }
export const numberFormatShort = ( numberVal, precision = 0) =>
{
  let n_format='';
  let suffix = '';
  if (numberVal < 999) {
      // 0 - 999
      n_format = numberVal.toFixed(precision);
      suffix = '';
  } else if (numberVal < 900000) {
      // 0.9k-850k
      n_format = (numberVal / 1000).toFixed(precision);
      suffix = 'K';
  } else if (numberVal < 900000000) {
      // 0.9m-850m
      n_format = (numberVal / 1000000).toFixed(precision);
      suffix = 'M';
  } else if (numberVal < 900000000000) {
      // 0.9b-850b
      n_format = (numberVal / 1000000000).toFixed(precision);
      suffix = 'B';
  } else {
      // 0.9t+
      n_format = (numberVal / 1000000000000).toFixed(precision);
      suffix = 'T';
  }
  return n_format + suffix;
}