// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.js';
import "assets/styles/developer.css";
import "react-notifications/lib/notifications.css";
import React, { useLayoutEffect, useEffect } from "react";
import moment from 'moment';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import ErrorBoundary from "./components/_common/ErrorBoundary/ErrorBoundary";
import { NotificationContainer } from "react-notifications";
import AppRouteWrapper from "routes/AppRouteWrapper";
import { HelmetProvider } from "react-helmet-async";
import { getCurrentLocation } from "store/actions/AuthAction";
import { routeScrollToTop } from "utils/CommonHelper";
import { loadCache } from "utils/AwsHelper";
import { saveCache } from "utils/AwsHelper";
import ComingSoon from "components/_common/ErrorPages/ComingSoon";
import { connect } from "react-redux";
import { settingsSelector } from "components/_selectors/authSelectors";
import HeaderInfo from "components/_common/Header/HeaderInfo";
import { PreloaderComponent } from "components/_common/Preloader";

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};
// scroll to top on route change
const Wrapper = ({ children }) => {
  const location = useLocation();
  const isPop = useBackButton();
  useLayoutEffect(() => {
    // every route change
    routeScrollToTop();
  }, [location.pathname, isPop]);
  // useLayoutEffect(() => {
  //   // on press browser back button
  //   setTimeout(() => {
  //     routeScrollToTop();
  //   }, 500);
  // }, [isPop]);
  return children;
};
function App({ settings }) {
  // get current location
  useEffect(() => {
    // get location
    // if (
    //   window.location.hostname === "localhost" ||
    //   window.location.hostname === "main.d3jbuy5sy5oxuv.amplifyapp.com"
    // ) {
      getCurrentLocation();
    // }
    // load cache from localstorage
    loadCache();
  }, []);
  useEffect(() => {
    window.addEventListener("beforeunload", saveCache);
    return () => {
      window.removeEventListener("beforeunload", saveCache);
      saveCache();
    };
  }, []);
  // if (
  //   ((window.location.hostname === "localhost" ||
  //   window.location.hostname === "main.d3jbuy5sy5oxuv.amplifyapp.com") && moment() > moment("2024-01-01"))
  // ) {
    if (settings && Object.keys(settings).length > 0) {
      return (
        <HelmetProvider>
          <ErrorBoundary>
            <NotificationContainer />
            <Wrapper>
              <AppRouteWrapper />
            </Wrapper>
          </ErrorBoundary>
        </HelmetProvider>
      );
    } else {
      return (
        <HelmetProvider>
          <HeaderInfo title={""} description={""} keywords={""} />
          <PreloaderComponent height={"50px"} extraClass={"p-7"} />
        </HelmetProvider>
      );
    }
  // } else {
  //   return (
  //     <HelmetProvider>
  //       <ErrorBoundary>
  //         <ComingSoon />
  //       </ErrorBoundary>
  //     </HelmetProvider>
  //   );
  // }
}

const mapStateToProps = (state) => {
  return {
    settings: settingsSelector(state),
  };
};
export default connect(mapStateToProps, {})(App);
