import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Preloader } from "components/_common/Preloader";

export default function ContentPageModal(props) {
  const { modalDetails, showModal, handleOnModalClose, openProductReward } =
    props;
    const [loading, setLoading] = useState(true);
  return (
    <Modal
      show={showModal}
      size={"lg"}
      onHide={handleOnModalClose}
      id="contentpageModal"
      centered
    >
      <Modal.Header closeButton>
        <h6 className="modal-title text-uppercase" id="contentpageModalLabel">
          {modalDetails?.title}
        </h6>
      </Modal.Header>
      <Modal.Body>
        {modalDetails ? (
          <iframe src={modalDetails.url} width={(loading)?"0%":"100%"} height={(loading)?"0%":"100%"} onLoad={() => setLoading(false)}/>
        ) : ""}
        { loading && <Preloader height={"50px"} extraClass={"p-7 pt-2"} />}
      </Modal.Body>
    </Modal>
  );
}
