import React from "react";
import { useTranslation } from "react-i18next";
import config from "config/config";

export default function BrandLogo({ logoClass, layout }) {
  const { t } = useTranslation();
  return (
    <>
      <img
        src={config.APP_LOGO}
        alt={t("app_name")}
        className={`${logoClass ? logoClass : ""}`}
      />
      <div className="brand-section d-none">
        {/* <h6 className="mb-0 mt-2 ms-1">{t("app_name")}</h6> */}
        {layout && layout == "signin" ? (
          <h2 className="mb-0">{t("app_name")}</h2>
        ) : (
          <h6 className="mb-0">{t("app_name")}</h6>
        )}
        <small className="text-gray-600">{t("app_tag_line")}</small>
      </div>
    </>
  );
}
