import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
export default function ConfirmDialogModal(props) {
    const { modalDetails, showModal, onConfirmFollowVendor, handleOnModalClose } = props;
    const [modal, setModal] = useState(false);
  return (
    <Modal show={showModal} onHide={handleOnModalClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>{modalDetails.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalDetails.message}</Modal.Body>
        <Modal.Footer>
            <Button className='mfw-100' variant="secondary" onClick={handleOnModalClose}>
            No
            </Button>
            <Button className='mfw-100' variant="primary" onClick={onConfirmFollowVendor}>
            Yes
            </Button>
        </Modal.Footer>
    </Modal>
  )
}
