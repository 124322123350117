import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate } from "react-router-dom";
import { mainRoutes as routes, loginRoutes } from "config/routing";
import { encryptObject, decryptObject, decryptText } from "utils/CommonHelper";
import { successMessage, errorMessage } from "utils/ToastNotificationHelper";
import HeaderInfo from "components/_common/Header/HeaderInfo";
import {
  userLocationSelector,
  userInfoSelector,
} from "components/_selectors/authSelectors";
import { verifyUser, resendOTP } from "store/actions/AuthAction";
import { storeUserInfo } from "utils/StorageHelper";
import config from "config/config";
import SigninLayout from "layouts/SigninLayout";
function VerifyOtp(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const resendSec = 30;
  const [resendCount, setResendCount] = useState(resendSec);
  const [eye, seteye] = useState(true);
  const onEyeClick = () => {
    seteye(!eye);
  };
  // const otp = decryptText(params.otp);
  // console.log(otp);
  useEffect(() => {
    if (props.userInfo) {
      setOtp(props.userInfo.otp);
    }
  }, [props.userInfo]);

  // useEffect(() => {
  //   if (otp) {
  //     successMessage("OTP: " + otp);
  //   }
  // }, [otp]);

  useEffect(() => {
    const id = setInterval(() => {
      if (resendCount > 0) {
        setResendCount((oldCount) => {
          if (oldCount > 0) {
            return oldCount - 1;
          }
        });
      }
    }, 1000);
    if (resendCount < 0) {
      clearInterval(id);
    }
    return () => {
      clearInterval(id);
    };
  }, []);

  const validateOTP = (e) => {
    let inputOTP = e.target.value;
    let otpError = "";
    if (!inputOTP) {
      otpError = "OTP is required";
    } else if (inputOTP != otp) {
      otpError = "Enter valid OTP";
    }
    setErrorMsg(otpError);
    if (otpError) {
      // console.log(otpError);
    } else {
      otpSubmitCall(inputOTP);
    }
  };
  const resendOTPCall = () => {
    if (!loading && !resendCount) {
      setLoading(true);
      resendOTP((success, response) => {
        setLoading(false);
        if (success) {
          var profileInfo = response.data;
          var userInfo = props.userInfo;
          var otp = response.data.otp;
          userInfo.otp = otp;
          storeUserInfo(userInfo);
          successMessage(response.message);
          setOtp(otp);
          setResendCount(resendSec);
        } else {
          // error
          errorMessage(response.message);
        }
      });
    }
  };
  const otpSubmitCall = (inputOTP) => {
    if (!loading) {
      setLoading(true);
      var apiPayload = {
        otp: inputOTP,
      };
      verifyUser(apiPayload, (success, response) => {
        setLoading(false);
        if (success) {
          var profileInfo = response.data;
          var userInfo = props.userInfo;
          userInfo.profileData = profileInfo;
          userInfo.verified = true;
          storeUserInfo(userInfo);
          if (profileInfo?.is_profile_completed) {
            navigate(routes.home.path);
          } else {
            navigate(loginRoutes.signinProfile.path);
          }
        } else {
          // error
          errorMessage(response.message);
        }
      });
    }
  };
  return (
    <>
      <HeaderInfo 
        title={t("signin_page_meta_title", { app_name: t("app_name") })} 
        description={t("signin_page_meta_description", { app_name: t("app_name") })}
        keywords={t("signin_page_meta_keyword")}
      />
      {props.userInfo && Object.keys(props.userInfo).length > 0 && (
        <SigninLayout>
          <div className="align-items-center text-center mb-3">
            <h5 className="mb-0">Enter The Verification Code</h5>
            <small className="form-text text-primary">
              Enter the 6 digit number that we sent to{" "}
            </small>
            <p className="form-text text-muted">
              +{props.userInfo?.userMobileCode} ******
              {props.userInfo.userMobile
                ? props.userInfo.userMobile.substring(
                    props.userInfo.userMobile.length - 4
                  )
                : ""}
            </p>
          </div>
          <div className="mb-4">
            <label className="form-label" htmlFor="loginUsername">
              Enter OTP
            </label>
            <div class="textbox-group">
              <input
                className="input-textbox form-control"
                onChange={(e) => validateOTP(e)}
                name=""
                id=""
                type={eye ? "password" : "text"}
                placeholder="OTP"
                autoComplete="off"
                required
                data-msg="Please enter OTP"
              />
              <i
                onClick={onEyeClick}
                className={`fa eye-icon text-gray-400 ${
                  eye ? "fa-eye-slash" : "fa-eye"
                } `}
              />
            </div>
            <span className="text-danger d-block">{errorMsg}</span>
          </div>
          <div className="d-flex">
            {/* style={{cursor: resendCount === 0 ? '' : 'not-allowed'}} */}
            <a
              onClick={() => resendOTPCall()}
              style={{ cursor: resendCount ? "not-allowed" : "pointer" }}
              className="text-start nav-link text-primary"
            >
              Didn't Get OTP?
            </a>
            <span className="ms-3 text-primary">
              {resendCount ? resendCount + "s" : ""}
            </span>
          </div>
        </SigninLayout>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    currentLocation: userLocationSelector(state),
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(VerifyOtp);
