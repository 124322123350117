import store from "store";
import APIService from "services/api.service";
import api from "config/api";
import { getUserAuthToken } from "utils/StorageHelper";
import { errorMessage } from "utils/ToastNotificationHelper";
export const getDashboardData = async (data, callback) => {
    var response = {};
    if(getUserAuthToken()){
        // logged in
        data.limit = (data.offer_limit)?data.offer_limit: data.limit;
        const apiOfferResponse = await APIService.submitData(api.offerList.url, data);
        if(apiOfferResponse){
            if(apiOfferResponse.status){
                // success
                // response.vendor_list = apiOfferResponse.data?.vendor_list;
                response.offer_list = apiOfferResponse?.data;
                // response.outlet_list = apiResponse.data?.outlet_list;
            }
            else{
                // errorMessage(apiOfferResponse.message);
            }
        }
        data.limit = (data.vendor_limit)?data.vendor_limit: data.limit;
        const apiVendorResponse = await APIService.submitData(api.vendorList.url, data);
        if(apiVendorResponse){
            if(apiVendorResponse.status){
                // success
                response.vendor_list = apiVendorResponse?.data;
            }
            else{
                // errorMessage(apiVendorResponse.message);
            }
        }
        data.limit = (data.outlet_limit)?data.outlet_limit: data.limit;
        const apiOutletResponse = await APIService.submitData(api.outletList.url, data);
        if(apiOutletResponse.status){
            // success
            response.outlet_list = apiOutletResponse?.data;
        }
        else{
            // errorMessage(apiOutletResponse.message);
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.publicDashboard.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response.vendor_list = apiResponse.data?.vendor_list;
                response.offer_list = apiResponse.data?.offer_list;
                response.outlet_list = apiResponse.data?.outlet_list;
            }
            else{
                // errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getOfferList = async (data, callback) => {
    var response = {};
    if(getUserAuthToken()){
        // logged in
        const apiResponse = await APIService.submitData(api.offerList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.publicOfferList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getOfferDetail = async (data, callback) => {
    var response = {};
    if(getUserAuthToken()){
        // logged in
        const apiResponse = await APIService.submitData(api.offerDetail.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.publicOfferDetail.url, data);
        if(apiResponse){    
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getVendorList = async (data, callback) => {
    var response = {};
    if(getUserAuthToken()){
        // logged in
        const apiResponse = await APIService.submitData(api.vendorList.url, data);
        // const apiResponse = await APIService.submitPublicData(api.publicVendorList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.publicVendorList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getSimilarVendorList = async (data, callback) => {
    var response = {};
    const apiResponse = await APIService.submitPublicData(api.publicSimilarVendorList.url, data);
    if(apiResponse){
        if(apiResponse.status){
            // success
            response = apiResponse?.data;
        }
        else{
            errorMessage(apiResponse.message);
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getVendorDetail = async (data, callback) => {
    var response = {};
    if(getUserAuthToken()){
        // logged in
        const apiResponse = await APIService.submitData(api.vendorDetail.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.publicVendorDetail.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getOutletList = async (data, callback) => {
    var response = {};
    if(getUserAuthToken()){
        // logged in
        // const apiResponse = await APIService.submitPublicData(api.publicOutletList.url, data);
        const apiResponse = await APIService.submitData(api.outletList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.publicOutletList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getOutletDetail = async (data, callback) => {
    var response = {};
    if(getUserAuthToken()){
        // logged in
        const apiResponse = await APIService.submitData(api.outletDetail.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.publicOutletDetail.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getVendorProductList = async (data, callback) => {
    var response = {};
    if(getUserAuthToken()){
        // logged in
        const apiResponse = await APIService.submitData(api.productList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.publicProductList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getVendorPhotoList = async (data, callback) => {
    var response = {};
    if(getUserAuthToken()){
        // logged in
        const apiResponse = await APIService.submitPublicData(api.publicPhotoList.url, data);
        // const apiResponse = await APIService.submitData(api.outletList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.photoList.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
export const getCategoryList = async (callback) => {
    const response = await APIService.getPublicData(api.categoryList.url, {});
    if(response){
        callback(response.status,response);
    }
};
export const submitVendorReview = async (data, callback) => {
    const response = await APIService.submitData(api.submitRatingReview.url, data);
    if(response){
        callback(response.status,response);
    }
};
export const getVendorReviewList = async (data, callback) => {
    // const response = await APIService.submitData(api.allRatingReview.url, data);
    // if(response){
    //     callback(response.status,response);
    // }
    var response = {};
    if(getUserAuthToken()){
        // logged in
        const apiResponse = await APIService.submitData(api.allRatingReview.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    else{
        const apiResponse = await APIService.submitPublicData(api.publicAllRatingReview.url, data);
        if(apiResponse){
            if(apiResponse.status){
                // success
                response = apiResponse?.data;
            }
            else{
                errorMessage(apiResponse.message);
            }
        }
    }
    
    if(Object.keys(response).length > 0){
        callback(response);
    }
};
